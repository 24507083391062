<template>

  <div>

    <p class="primary--text pt-4">{{ $t('OFFICE.INFO') }}</p>
    <v-row class="mt-0">
      <v-col cols="8" class="pb-0 pt-0">
        <v-select
          v-model="company_id"
          @change="$emit('input', {company_id: $event})"
          :items="companyItems"
          item-value="id"
          item-text="title"
          :rules="[$rules.required]"
          :label="$t('OFFICE.FIELD_COMPANY')"
          :placeholder="$t('OFFICE.FIELD_COMPANY_PLACEHOLDER')"
          outlined
          dense
        />
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col cols="8" class="pb-0 pt-0">
        <v-text-field
          v-model="title"
          @change="$emit('input', {title: $event})"
          :rules="[$rules.required, $rules.maxlength(80)]"
          :label="$t('OFFICE.FIELD_TITLE')"
          :placeholder="$t('OFFICE.FIELD_TITLE_PLACEHOLDER')"
          outlined
          dense
        />
      </v-col>
      <v-col cols="4" class="pb-0 pt-0">
        <v-select
          v-model="country_id"
          @change="$emit('input', {country_id: $event})"
          :items="countryItems"
          item-value="id"
          item-text="title"
          :rules="[$rules.required]"
          :label="$t('PRODUCT.FIELD_COUNTRY')"
          :placeholder="$t('PRODUCT.FIELD_COUNTRY_PLACEHOLDER')"
          outlined
          dense
        />
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col cols="9" class="pb-0 pt-0">
        <v-textarea
          v-model="description"
          @change="$emit('input', {description: $event})"
          required
          :rules="[$rules.required, $rules.maxlength(1023)]"
          :label="$t('OFFICE.FIELD_DESCRIPTION')"
          :placeholder="$t('OFFICE.FIELD_DESCRIPTION_PLACEHOLDER')"
          outlined
          dense
        />
      </v-col>
      <v-col cols="3">
        <InputImage
        v-model="images"
        :formats="[
         {name: 'lg', height: 200 },
         {name: 'md', height: 100 },
         {name: 'sm', height: 30 }
        ]"
        :label="$t('COMMON.IMAGE')"
        @input="$emit('input', {images: $event})"
         />
      </v-col>
    </v-row>

    <p class="primary--text pt-4">{{ $t('OFFICE.CONTACT') }}</p>
    <v-row class="mt-0">
      <v-col cols="6" class="pb-0 pt-0">
        <v-text-field
          v-model="contact_name"
          @change="$emit('input', {contact_name: $event})"
          :rules="[$rules.required, $rules.maxlength(254)]"
          :label="$t('OFFICE.FIELD_NAME')"
          :placeholder="$t('OFFICE.FIELD_NAME_PLACEHOLDER')"
          outlined
          dense
        />
      </v-col>
      <v-col cols="6" class="pb-0 pt-0">
        <v-text-field
          v-model="contact_email"
          @change="$emit('input', {contact_email: $event})"
          :rules="[$rules.email, $rules.required, $rules.maxlength(254)]"
          :label="$t('OFFICE.FIELD_EMAIL')"
          :placeholder="$t('OFFICE.FIELD_EMAIL_PLACEHOLDER')"
          outlined
          dense
        />
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="12" class="pb-0 pt-0">
        <v-textarea
          v-model="contact_address"
          @change="$emit('input', {contact_address: $event})"
          rows="2"
          :rules="[$rules.required, $rules.maxlength(1023)]"
          :label="$t('OFFICE.FIELD_ADDRESS')"
          :placeholder="$t('OFFICE.FIELD_ADDRESS_PLACEHOLDER')"
          outlined
          dense
        />
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col cols="12" class="pb-0 pt-0">
        <v-checkbox
          v-model="show_public"
          @change="$emit('input', {show_public: !!$event})"
          :label="$t('COMMON.SHOW_PUBLIC')"
          :placeholder="$t('COMMON.SHOW_PUBLIC')"
          :messages="$t('COMMON.SHOW_PUBLIC_INFO') "
          outlined
          dense
        />
      </v-col>
    </v-row>

  </div>

</template>

<script>
import { mapState } from 'vuex'
import InputImage from '@/components/InputImage'

export default {
  name:"FormOfficeCompactIntro",
  components: {
    InputImage
  },
  data: (_this) => ({
    countryItems: [],
    companyItems: [],
    //
    images: undefined,
    company_id: undefined,
    country_id: undefined,
    title: undefined,
    description: undefined,
    contact_name: undefined,
    contact_email: undefined,
    contact_address: undefined,
    show_public: undefined,
  }),
  props: {
    values: {
      type: [Object, undefined],
      default: () => ({})
    },
    
  },
  watch: {
    values: {
      handler () {
        this.attribValues()
      },
      deep: true,
    },
  },
  computed: {
    ...mapState('user', {
      userRole: state => state.user.role.title,
      userCompany: state => state.user.company,
    }),
  },
  created () {
    if (this.userRole === "manager") {
      this.companyItems = this.userCompany
    }
    else this.loadCompanyItems()
    this.loadCountryItems()
    this.attribValues()
  },
  methods: {
    attribValues () {
      const values = this.$clone(this.values)
      Object.keys(values).forEach(key => {
        if (key === 'date' && typeof values[key] === 'string') values[key] = values[key].slice(0,10)
        this[key] = values[key]
      })
    },

    loadCompanyItems () {
      this.loading = true
      this.$services.api_user.company
        .search({where: {status: [1]}})
        .then(response => {
          if (response.data) {
            this.companyItems = response.data
          } else {
            this.companyItems = []
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    loadCountryItems () {
      this.loading = true
      this.$services.api_user.country
        .search({where: {status: [1]}})
        .then(response => {
          if (response.data) {
            this.countryItems = response.data
          } else {
            this.countryItems = []
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

  }
}
</script>
